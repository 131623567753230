.react-calendar-heatmap .color-scale-1 {
    fill: rgba(6, 27, 100, 0.3) !important;
}

.react-calendar-heatmap .color-scale-2 {
    fill: rgba(6, 27, 100, 0.5) !important;
}

.react-calendar-heatmap .color-scale-3 {
    fill: rgba(6, 27, 100, 0.7) !important;
}

.react-calendar-heatmap .color-scale-4 {
    fill: rgba(6, 27, 100, 0.9) !important;
}